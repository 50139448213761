<script>
import { app } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import reportsMixin from '@/modules/Reports/reportsMixin'
import { format } from 'date-fns'

const TRANSLATION = {
  description: {
    description: app.$t('global:form.events.description'),
    audience: app.$t('global:form.events.audience'),
    differentials: app.$t('global:form.events.differentials')
  }
}

export default {
  name: 'AgendasManage',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    InputField: () => import('@/components/general/InputField'),
    SelectField: () => import('@/components/general/SelectField'),
    Radio: () => import('@/components/general/Radio'),
    ContentHeader: () => import('@/components/general/ContentHeader')
  },

  mixins: [ reportsMixin ],

  data () {
    return {
      linkToEventTabActive: 0,
      linkToEventTab: [
        { text: 'event.manage:link.tab.1' },
        { text: 'event.manage:link.tab.2' }
      ],

      events: [],
      disableEventSelection: false,
      formData: {
        id: null,
        name: '',
        event: null,
        modality: 'Online',
        locality: null,
        startDate: null,
        endDate: null
      },

      backUrl: { name: 'events.agendas.index' },

      eventPagination: {
        query: { name: '' },
        filter: {
          event: null,
          not_id: [],
          active: true
        },

        order: [],
        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 99999,
        is_default_search: true
      },

      agendaTypeOptions: [
        {
          label: this.$t('agendas.manage:agendaTypeOptions.online'),
          value: 'Online'
        },
        {
          label: this.$t('agendas.manage:agendaTypeOptions.presencial'),
          value: 'Presencial'
        }
      ]
    }
  },

  validations () {
    return {
      formData: {
        name: { required },
        event: { required },
        modality: { required },

        locality: {
          required: requiredIf(function () {
            return this.formData.modality === 'Presencial'
          })
        },

        startDate: {
          required,
          dateAfter: function (event) {
            if (this.isEditing) return true

            return this.isDateAfter(new Date(event), new Date(this.formData.endDate))
          }
        },

        endDate: {
          required,
          dateBefore: function (event) {
            if (this.isEditing) return true

            return this.isDateBefore(new Date(this.formData.startDate), new Date(event))
          },

          notEqualStartDate: function (event) {
            if (this.isEditing) return true
            if (!this.formData.startDate || !this.formData.endDate) return true

            return !(this.formData.startDate === event)
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),

    isEditing () {
      return !!(this.$route.params.id || this.formData.id || false)
    }
  },

  created () {
    this.setup()
    this.setFetching(true)

    if (this.$route.params.event) {
      this.eventPagination.filter.event = this.$route.params.event
      this.disableEventSelection = true
    }

    Promise.all([ this.getEventsList() ])
      .catch(() => {
        this.setFeedback({ message: this.$t('agendas.load:fetch.error') })
        this.$router.push({ name: 'events.agendas.index' })
      })
      .finally(() => {
        if (this.isEditing) {
          this.setFetching(true)

          this.attemptManageAgenda(this.$route.params.id)
            .then((response) => {
              this.formData = response
              this.formData.event = response.event.id
              this.formData.startDate = this.getFormattedDate(response.startDate)
              this.formData.endDate = this.getFormattedDate(response.endDate)
            })
            .catch(() => {
              this.setFeedback({ message: this.$t('agendas.load:fetch.error') })
              this.$router.push({ name: 'events.agendas.index' })
            })
            .finally(() => {
              this.setFetching(false)
            })
        } else {
          this.setFetching(false)

          if (this.$route.params.event) {
            this.formData.event = this.$route.params.event
          }
        }

        this.$v.$reset()
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptUpdateAgenda',
      'attemptUpdateActiveAgenda',
      'attemptManageAgenda',
      'attemptSaveActiveAgenda',
      'attemptGetEventsList'
    ]),

    formatFormData () {
      const data = {}

      data.id = this.formData.id
      data.name = this.formData.name
      data.event = this.formData.event
      data.modality = this.formData.modality
      data.locality = this.formData.modality === 'Presencial' ? this.formData.locality : null
      data.startDate = this.formData.startDate
      data.endDate = this.formData.endDate
      data.active = this.formData.active || false

      return data
    },

    submitCreation () {
      this.setFetching(true)

      this.attemptSaveActiveAgenda({ data: this.formatFormData() })
        .then(({ data }) => {
          this.$router.push({
            name: 'events.agendas.manage',
            params: {
              id: data.id,
              isNew: 'edit'
            }
          })

          this.setFeedback({ message: this.$t('agendas:feedback.created.success') })
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response)

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateAgenda({
        id: this.$route.params.id,
        data: formData
      })
        .then(() => {
          this.setFeedback({ message: this.$t('agendas:feedback.updated.success') })
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response)

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit (next) {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
          resolve(true)
        } else {
          this.setFeedback({ message: this.$t('agendas:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    getEventsList () {
      this.setFetching(true)

      this.eventPagination.is_default_search = this.eventPagination.page === 1 &&
      this.eventPagination.query.name.length === 0

      this.eventPagination.filter.not_id = this.formData.id ? [ this.formData.id ] : []

      this.attemptGetEventsList(this.eventPagination)
        .then(pagination => {
          this.eventPagination.lastPage = pagination.lastPage
          this.eventPagination.actualPage = pagination.page
          this.events = []

          if (pagination.data) {
            pagination.data.forEach(element => {
              this.events.push({
                text: element.name,
                value: element.id
              })
            })

            return true
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getParsedErrorMessage (response) {
      if (response.data.error.message === 'event__past_schedule_not_allowed') {
        return this.$t('agendas:feedback.past.schedule.error')
      }

      if (response.data.error.code === 10) {
        return response.data.error.message
      }

      return this.$t('agendas:feedback.created.error')
    },

    setup () {
      this.TRANSLATION = TRANSLATION
    },

    getFormattedDate (date) {
      const formattedDate = format(
        new Date(date),
        'yyyy-MM-dd HH:mm'
      )

      return formattedDate
    }
  }
}
</script>

<template>
  <div class="main-content agendas-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('agendas.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.agendas')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />

      <ActionBar slot="actionbar" />

      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit(false)"
        />
      </template>
    </ContentHeader>

    <div class="main-content-inner">
      <div class="center">
        <div class="agendas-create-header">
          <h2 class="agendas-create-title">
            {{ $t('agendas.create:header.title') }}
          </h2>

          <p
            class="agendas-create-description"
            v-html="$t('agendas.create:header.description')"
          />

          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>

          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>

        <form
          class="form"
          @submit.prevent="submit"
        >
          <SelectField
            v-model="formData.event"
            tabindex="0"
            :label="$t('global:form.agendas.event')"
            :items="events"
            :validation="$v.formData.event"
            :disabled="isEditing || disableEventSelection"
          />

          <InputField
            v-model="formData.name"
            :label="$t('global:form.agendas.title')"
            :validation="$v.formData.name"
            :counter="100"
          />

          <FormSection :title="$t('global:form.agendas.modality')">
            <Radio
              v-model="formData.modality"
              :under-description="$t('agendas.manage:field.description.presencial')"
              :items="agendaTypeOptions"
              horizontal
              :validation="$v.formData.modality"
            />
          </FormSection>

          <InputField
            v-if="formData.modality === 'Presencial'"
            v-model="formData.locality"
            :label="$t('global:form.agendas.local')"
            :validation="$v.formData.locality"
            :counter="120"
          />

          <FormSection
            class="form-section-deadline"
            :title="$t('global:form.agendas.date')"
          >
            <div class="event-deadline">
              <Datepicker
                v-model="formData.startDate"
                :label="$t('agendas.manage:field.startDate')"
                :validation="$v.formData.startDate"
                :disabled="isEditing"
                time
                :min="isEditing ? null : new Date()"
                @mousewheel="blur($event)"
              />
            </div>

            <div class="event-deadline">
              <Datepicker
                v-model="formData.endDate"
                :label="$t('agendas.manage:field.endDate')"
                :validation="$v.formData.endDate"
                :disabled="isEditing"
                time
                :min="isEditing ? null : formData.startDate ? new Date(formData.startDate) : new Date()"
                @mousewheel="blur($event)"
              />
            </div>
          </FormSection>
        </form>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/agendas.css"></style>

<style lang="scss" src="../assets/scss/Agendas.scss"></style>

<style>
.link-selection,
.link-selection .form-section {
  display: flex;
  margin: 40px auto 32px;
  flex-direction: column;
}

.link-selection .form-section + .form-section {
  margin-top: 0;
}

.link-selection .form-section {
  width: 480px;
}

.link-selection .form-section.form-section--centered {
  text-align: center;
}

.link-selection .search-field,
.link-selection .link-table > div {
  width: 50%;
}

.link-table {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.agendas-create-header + .center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}

.td-tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  line-height: 30px;
  padding: 0 10px;
  min-width: 70px;
  text-align: center;
  background: #F3F4F7;
  border-radius: 50px;
  text-transform: none;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}
</style>
